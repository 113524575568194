button:disabled {
  opacity: 0.8;
}

.list-show {
  bottom: 0 !important;
}

.wrap-modal-device-frame::-webkit-scrollbar {
  width: 5px;
}
.wrap-modal-device-frame::-webkit-scrollbar-thumb {
  background: #aaa; 
  border-radius: 10px;
}

.MuiDataGrid-columnHeaderTitleContainerContent input, .MuiDataGrid-columnHeaderTitleContainerContent svg  {
  display: none !important;
}

div[title="highlight-0"] {
  background: none;
  border: 2px solid #FFF;
}

div[title="highlight-25"] {
  background: linear-gradient(173deg, rgba(147, 223, 147, 0.25) 0%, rgba(33, 194, 193, 0.25) 100%);
  border: 2px solid #FFF;
}

div[title="highlight-50"] {
  background: linear-gradient(173deg, rgba(147, 223, 147, 0.5) 0%, rgba(33, 194, 193, 0.5) 100%);
  border: 2px solid #FFF;
}

div[title="highlight-75"] {
  background: linear-gradient(173deg, rgba(147, 223, 147, 0.75) 0%, rgba(33, 194, 193, 0.75) 100%);
  border: 2px solid #FFF;
}

div[title="highlight-100"] {
  background: linear-gradient(173deg, rgba(147, 223, 147, 1) 0%, rgba(33, 194, 193, 1) 100%);
  border: 2px solid #FFF;
}